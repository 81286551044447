import { forwardRef, useEffect } from 'react';

function Webcam({ stream }, videoRef) {
  useEffect(() => {
    if (stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, videoRef]);

  return (
    <>
      {stream && (
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="block w-full h-full object-cover"
        />
      )}
    </>
  );
}

export default forwardRef(Webcam);
