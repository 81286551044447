import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import { api } from '../../lib/api';
import MobileModal from './MobileModal';
import { cropImage } from '../../lib/cropImage';
import Webcam from './Webcam';
import { useCamera } from '../../hooks/useCamera';
import CameraControl from './CameraContorl';

const videoConstraints = {
  width: 1920,
  height: 1080,
  facingMode: 'environment',
};

export default function Mobile() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null); // 스크린샷을 찍기 위한 캔버스
  const guideLineRef = useRef(null);
  const audioRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [guidelineRect, setGuidelineRect] = useState();
  const [mobileSize, setMobileSize] = useState(); // 모바일 화면 크기
  const [showFlash, setShowFlash] = useState(false);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    stream,
    isTorch,
    isFlash,
    track,
    toggleFacingMode,
    videoResolution,
    toggleTorch,
  } = useCamera(videoConstraints);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const handleFlash = () => {
    audioRef.current.play();

    // 애니메이션 클래스를 적용하여 깜빡거리는 효과를 실행
    setShowFlash(true);

    // 애니메이션이 끝난 후 클래스를 제거
    setTimeout(() => {
      setShowFlash(false);
    }, 200); // 애니메이션 지속 시간과 일치시켜야 함
  };

  const handleSubmit = useCallback(
    async (originalImageSrc, cropPhoto) => {
      const today = moment.now();
      try {
        const response = await api
          .post('api/image', {
            json: {
              image: originalImageSrc,
              capture_tick: today,
              bbox: cropPhoto.bbox,
            },
          })
          .json();
        console.log(cropPhoto)
        const data = response;
        setData(data);
        openModal();
      } catch (error) {
        console.log(error, 'error');
        toast.error('문자를 인식할 수 없습니다. 다시 시도해 주세요.');
      }
    },
    [openModal]
  );

  const capture = async () => {
    canvasRef.current.width = videoRef.current.videoWidth;
    canvasRef.current.height = videoRef.current.videoHeight;

    canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0);
    const originalImageSrc = canvasRef.current.toDataURL('image/jpg');

    handleFlash();

    const cropPhoto = await cropImage(
      originalImageSrc,
      guidelineRect,
      videoResolution,
      mobileSize
    );

    setImgSrc(cropPhoto);

    handleSubmit(originalImageSrc, cropPhoto);
  };

  useEffect(() => {
    if (guideLineRef.current) {
      const guideLine = guideLineRef.current;
      const rect = guideLine.getBoundingClientRect();

      setGuidelineRect(rect);
    }
  }, [guideLineRef]);

  useEffect(() => {
    setMobileSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full relative">
      <div className="absolute top-0 left-0 w-full h-full">
        <Webcam ref={videoRef} stream={stream} />
        {/* 촬영 가이드용 사각 박스 */}
        <div
          style={{
            top: '200px',
            left: '50%',
            transform: 'translateX(-50%)',
            borderWidth: '4px',
          }}
          className="absolute border-white opacity-60 w-4/5 h-36"
        >
          <div ref={guideLineRef} className="w-full h-full"></div>
        </div>
      </div>
      {/* 버튼 컨트롤 */}
      {track && (
        <CameraControl
          isTorch={isTorch}
          toggleTorch={toggleTorch}
          isFlash={isFlash}
          capture={capture}
          toggleFacingMode={toggleFacingMode}
        />
      )}
      <div className="hidden">
        <canvas ref={canvasRef} />
      </div>

      <audio
        ref={audioRef}
        className="hidden"
        src="alarm.mp3"
        preload="auto"
      ></audio>
      {showFlash && <div className={styles.flash}></div>}
      {modalIsOpen && (
        <MobileModal imgSrc={imgSrc} closeModal={closeModal} data={data} />
      )}
      <Toaster />
    </div>
  );
}
