export function cropImage(imageSrc, guidelineRect, videoResolution, viewSize) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      // 비디오 해상도와 실제 이미지 크기 사이의 비율 계산
      const realviewRatio = img.height / viewSize.height;
      const realviewWidth = viewSize.width * realviewRatio;

      const viewbboxX = guidelineRect.left * realviewRatio;
      const viewbboxY = guidelineRect.top * realviewRatio;
      const viewbboxW = guidelineRect.width * realviewRatio;
      const viewbboxH = guidelineRect.height * realviewRatio;

      const offsetX = img.width / 2 - realviewWidth / 2;

      // 조정된 가이드라인과 스케일을 기반으로 자르기 치수 계산
      const cropX = offsetX + viewbboxX;
      const cropY = viewbboxY;
      const cropWidth = viewbboxW;
      const cropHeight = viewbboxH;
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.drawImage(
        img,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );

      const croppedImageSrc = canvas.toDataURL('image/png');

      resolve({
        image: croppedImageSrc,
        bbox: [cropX, cropY, cropWidth, cropHeight],
      });
    };
  });
}
