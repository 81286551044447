import { IoIosFlash, IoIosFlashOff } from 'react-icons/io';
import { IoCameraReverseOutline } from 'react-icons/io5';

export default function CameraControl({
  isTorch,
  toggleTorch,
  isFlash,
  capture,
  toggleFacingMode,
}) {
  return (
    <div className="w-full flex justify-around items-center absolute bottom-10 text-white z-10">
      <div>
        {isTorch ? (
          <button
            className="flex justify-center items-center"
            onClick={toggleTorch}
          >
            {isFlash ? <IoIosFlash size={40} /> : <IoIosFlashOff size={40} />}
          </button>
        ) : (
          <div className="w-10 h-10"></div>
        )}
      </div>
      <div>
        <button
          onClick={capture}
          className="p-1.5 border-white border-[3px] rounded-full"
        >
          <div className="w-6 h-6 bg-white rounded-full"></div>
        </button>
      </div>
      <div>
        <button
          className="flex justify-center items-center"
          onClick={toggleFacingMode}
        >
          <IoCameraReverseOutline size={40} />
        </button>
      </div>
    </div>
  );
}
